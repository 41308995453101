body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

form .form-field {
	display: block;
	margin-top: 10px;

	padding: 5px 0px;
}

/* Shared Animations */
.fade-in {
	-webkit-animation: fadein 1s ease-in; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s ease-in; /* Firefox < 16 */
	-ms-animation: fadein 1s ease-in; /* Internet Explorer */
	-o-animation: fadein 1s ease-in; /* Opera < 12.1 */
	animation: fadein 1s ease-in;
}

.fade-in-short {
	-webkit-animation: fadein 0.5s ease-in; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 0.5s ease-in; /* Firefox < 16 */
	-ms-animation: fadein 0.5s ease-in; /* Internet Explorer */
	-o-animation: fadein 0.5s ease-in; /* Opera < 12.1 */
	animation: fadein 0.5s ease-in;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
